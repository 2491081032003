import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Offcanvas, Modal, Dropdown } from "react-bootstrap";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/images/logo.png";
import numberWithCommas from "../utils/numbersWithComma";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";
import { handleRightSideBar } from "../redux/app/actions";
import FetchingBal from "../assets/images/icons/fetch.gif";
import GamesSearchModal from "../views/components/GamesSearchModal";

import Profile from "../assets/images/icons/menu/profile.svg";
import Deposit from "../assets/images/icons/menu/wallet.svg";
import Payout from "../assets/images/icons/menu/payout.svg";
import History from "../assets/images/icons/menu/bets.svg";
import Referral from "../assets/images/icons/menu/referral.svg";
import Bonus from "../assets/images/icons/menu/bonus.svg";
import Statement from "../assets/images/icons/menu/statement.svg";
import Logout from "../assets/images/icons/menu/logout.png";
import { ORG_CURRENCIES_SIGN, ORG_CURRENCY } from "../config/domainConst";
import CurrencyDropdown from "../views/components/CurrencyDropdown";
// import Currency from "../assets/images/currency-page/currency.svg";

const HeaderAfterLogin = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const [showBalance, setShowBalanceLoading] = useState(false);
  const [searchText, setSearchText] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    handleSideBarVisibility(false);
    setShow(false);
  };
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [fontConfig, setFontConfig] = useState({
    counter: 0,
    fontSize: 12,
    type: "inc",
  });

  const [showToggle, setShowToggle] = useState(true);

  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  useEffect(() => {
    // dispatch(getExposureAmount());
    // dispatch(getBetMatches({ status: "pending" }));
    setShowToggle(window.screen.width > 992);
    let intervalID = setInterval(() => {
      setShowBalanceLoading((p) => !p);
    }, 5000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  const handleSideBarVisibility = (value) => {
    dispatch(handleRightSideBar(value));
  };
  const handleFontConfig = () => {
    if (fontConfig.counter <= 2 && fontConfig.type == "inc") {
      if (fontConfig.counter === 2) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
          type: "dec",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
        }));
      }
    } else if (fontConfig.counter >= 0 && fontConfig.type == "dec") {
      if (fontConfig.counter === 0) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
          type: "inc",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
        }));
      }
    }
  };

  const renderBalance = () => {
    return (
      <>
        <div className="cashloader">
          {showBalance ? (
            <div className="balance-web">
              <ul>
                <li>
                  Cash: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                </li>
                <li>
                  Bonus:
                  <span> {numberWithCommas(wallet?.coins)}</span>
                  {/* <span> {numberWithCommas(exposureAmount || 0)}</span> */}
                </li>
                {/* <li>
                  Sports: <span> {numberWithCommas(exposureAmount || 0)}</span>
                </li> */}
              </ul>
            </div>
          ) : (
            <p>
              <span>
                <img src={FetchingBal} />
                Fetching Balance...
              </span>
            </p>
          )}
        </div>
      </>
    );
  };

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "betby",
    "exchange",
    "qtechbetgamestv",
    "casino",
    "home",
    "promotions",
    "blog",
    "/",
    "/betby?bt-path=%2Flive",
    "qtech",
  ];
  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const formattedDate = currentDateTime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = currentDateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    hour12: true,
  });

  return (
    <header className="header aftrlgn">
      <div className="top_head">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="logo">
              <a href="/home">
                <img src={appDetails?.LOGO_URL} alt="Logo" />
                {/* <img src={logo} alt="Logo" /> */}
              </a>
            </div>
          </div>

          <div className="headerRight">
            {/* <div className="currency d-md-none">
              <a href="change-currency">
                <img src={Currency} alt="currency" />
              </a>
            </div> */}
            <CurrencyDropdown textColor={"#ffffff"} />
            <div className="search-games d-md-none">
              <GamesSearchModal />
            </div>

            {/* {renderBalance()} */}
            <div className="balance-info">
              <div className="balance">
                <div className="main-bal">
                  <span></span>{" "}
                  <small>{ORG_CURRENCIES_SIGN?.[ORG_CURRENCY]}</small>{" "}
                  <strong>{numberWithCommas(wallet?.cash || 0)}</strong>
                </div>
                <div className="bonus-bal d-none">
                  <span>Bonus</span>{" "}
                  <small>{ORG_CURRENCIES_SIGN?.[ORG_CURRENCY]}</small>{" "}
                  <span>{numberWithCommas(wallet?.coins)}</span>
                </div>
              </div>

              {/* <div className="deposit">
                <a href="/gatewaylist">Deposit</a>
              </div> */}
            </div>

            <Dropdown align="end" className="d-none d-md-block">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {user?.mstruserid}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/profile">
                  <img src={Profile} alt="profile" />
                  <span>Account Profile</span>
                </Dropdown.Item>
                {/* <Dropdown.Item href="/gatewaylist">
                  <img src={Deposit} alt="deposit" />
                  <span>Deposit</span>
                </Dropdown.Item> */}
                <Dropdown.Item href="/withdraw">
                  <img src={Payout} alt="Payout" style={{ padding: "0 2px" }} />
                  <span>Payout</span>
                </Dropdown.Item>
                <Dropdown.Item href="/mybets">
                  <img src={History} alt="bets" />
                  <span>History</span>
                </Dropdown.Item>
                <Dropdown.Item href="/referral">
                  <img
                    src={Referral}
                    alt="referral"
                    style={{ padding: "0 2px" }}
                  />
                  <span>Referral</span>
                </Dropdown.Item>
                <Dropdown.Item href="/bonus">
                  <img src={Bonus} alt="bonus" />
                  <span>Bonuses</span>
                </Dropdown.Item>
                <Dropdown.Item href="/cashier">
                  <img src={Statement} alt="statement" />
                  <span>Account Statement</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href="/"
                  onClick={() => {
                    dispatch(logoutUser());
                  }}
                  className="logout"
                >
                  <img src={Logout} alt="logout" style={{ width: "18px" }} />
                  <span>Logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="bottom_head d-none d-md-block">
        <div className="bottom-content">
          <ul>
            <li>
              <a
                href="/betby"
                className={activePage == "betby" ? "active" : ""}
              >
                <span>Sport</span>
              </a>
            </li>
            <li>
              <a
                href="/betby?bt-path=%2Flive"
                className={activePage == "exchange" ? "active" : ""}
              >
                <span>Live</span>
              </a>
            </li>
            <li>
              <a
                href="/casino"
                className={activePage == "casino" ? "active" : ""}
              >
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a href="/home" className={activePage == "home" ? "active" : ""}>
                <span>Live Casino</span>
              </a>
            </li>
            <li>
              <a
                href="/casino/qtechbetgamestv-bet-on-poker"
                className={activePage == "qtechbetgamestv" ? "active" : ""}
              >
                <span>Bet Games</span>
              </a>
            </li>
            <li>
              <a
                href="/casino/qtech"
                className={activePage == "qtech" ? "active" : ""}
              >
                <span>Qtech</span>
              </a>
            </li>
            <li>
              <a
                href="/promotions"
                className={activePage == "promotions" ? "active" : ""}
              >
                <span>Promotions</span>
              </a>
            </li>
            <li>
              <a href="/blog" className={activePage == "blog" ? "active" : ""}>
                <span>Blog</span>
              </a>
            </li>
          </ul>

          <div className="lang-time-currency">
            {/* <div className="currency">
              <a href="change-currency">
                <img src={Currency} alt="currency" />
              </a>
            </div> */}
            <div className="search-games d-none d-md-block">
              <GamesSearchModal />
            </div>
            <div className="time">{formattedTime}</div>
          </div>
        </div>
      </div>

      <Modal
        className="betslip_popup outer_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showBetModal}
      >
        <Modal.Body>
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Exposure amount</th>
                    <th scope="col">Match name</th>
                    <th scope="col">Ref match ID</th>
                    <th scope="col">Total bets</th>
                  </tr>
                </thead>
                <tbody>
                  {betMatches &&
                    betMatches.length &&
                    betMatches.map((item, index) => {
                      return (
                        <tr
                          onClick={() => {
                            handleRowClick(item);
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td>{item?.exposureAmount}</td>
                          <td>{item?.matchName}</td>
                          <td>{item?.refMatchId}</td>
                          <td>{item?.totalBets}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="betslip_popup inner_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showUserBetModal}
      >
        <Modal.Body>
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Odds</th>
                    <th scope="col">Stack</th>
                    <th scope="col">Selection type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Create date</th>
                  </tr>
                </thead>
                <tbody>
                  {userBets &&
                    userBets.length &&
                    userBets.map((item, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.odds}</td>
                          <td>{item?.stack}</td>
                          <td>{item?.selectionType}</td>
                          <td>{item?.status}</td>
                          <td>{item?.createdAt}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </header>
  );
};

export default HeaderAfterLogin;
